import React, { useMemo } from "react";
import SearchBar from "./SearchBar";
import Navbar from "react-bootstrap/Navbar";
import LetterButtons from "./LetterButtons";
import logo from "../assets/reference_lab_logo.png";

import "../css/Header.css";
import { useHistory, useLocation } from "react-router-dom";

const Header = ({
    onTestTypeChange,
}: {
    onTestTypeChange: (value: number) => void;
}) => {
    const location = useLocation();
    const history = useHistory();

    const shouldDisable = useMemo(() => {
        const lName = location.pathname;
        let lNameSplit = lName.split("/");
        return lNameSplit[1] === "reference";
    }, [location.pathname]);

    return (
        <div className="header">
            <Navbar>
                <Navbar.Brand>
                    <a href="/">
                        <img src={logo} className="usaLogo" alt="usa logo" />
                    </a>
                </Navbar.Brand>
                <SearchBar />
            </Navbar>
            <div className="d-flex center-letters">
                <LetterButtons />
                <div className="typeSelect">
                    <label className="ml-3" htmlFor="cars">
                        <b>Test Type:</b>
                    </label>
                    <select
                        className="ml-2"
                        name="cars"
                        id="cars"
                        style={{ borderRadius: 8, padding: 5 }}
                        onChange={(event) => {
                            onTestTypeChange(parseInt(event.target.value));
                            history.push({
                                pathname: "/",
                                search: `?startsWith=-1`,
                            });
                        }}
                        disabled={shouldDisable}
                    >
                        <option value="0">All</option>
                        <option value="1">GenLab</option> {/*Is TestTypeId 1 & 2. This gets passed correctly as TestTypeId 1 to the backend. In the GetAllLabTest stored procedure TestTypeId 2 also gets associated with TesttypeId 1 to return both TestTypeId 1 & 2.*/}
                        <option value="2">Micro</option> {/*Is TestTypeId 3 so the value should be 3. This gets passed as TestTypeId 2 to the backend but gets corrected and mapped to the correct TesttypeId in the GetAllLabTest stored procedure*/}
                        <option value="3">Blood Bank</option> {/*Is TestTypeId 4 so the value should be 4. This gets passed as TestTypeId 3 to the backend but gets corrected and mapped to the correct TesttypeId in the GetAllLabTest stored procedure*/}
                        <option value="5">AP</option> {/*Is TestTypeId 5. This gets passed correctly as TestTypeId 5 to the backend*/}
                    </select>
                </div>
            </div>
        </div>
    );
};

export default Header;
