import React from "react";
import "../css/Footer.css";

function Footer() {
  return (
    <div className="footer">
      <h6 className="footerTitle">
        For Special Instructions or Further Information Contact
      </h6>
      <div>
        University Hospital - Main USA Lab - Ph:{" "}
        <a className="footerPhone" href="tel:251-471-7220">
          (251) 471-7220
        </a>
      </div>
      <div>
        Children's and Women's Hospital - Main USA Lab - Ph:{" "}
        <a className="footerPhone1" href="tel:251-415-1608">
          (251) 415-1608
        </a>
      </div>
    </div>
  );
}

export default Footer;
