import React from 'react';
import Button from 'react-bootstrap/esm/Button';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import { useHistory } from 'react-router-dom';
import { useContext } from '../contexts/AppContext';

function SearchBar(): JSX.Element {
    const { searchQuery, setSearchQuery } = useContext();

    const history = useHistory();

    function onSearch(e: any) {
        e.preventDefault();

        let params = `?startsWith=${searchQuery}`;

        history.push({
            pathname: "/",
            search: params
        })
    }

    return (
        <Form inline className="headerSearchForm" onSubmit={onSearch}>
            <FormControl 
                type="text" 
                placeholder="Test Name" 
                className="headerSearchBar" 
                value={searchQuery} 
                onChange={e => setSearchQuery(e.target.value)} 
            />
            <Button className="headerSearchBtn" onClick={onSearch}>Search</Button>
        </Form>
    );
}

export default SearchBar;